<template>

	<lg-menu/>

	<div class="main-content">
		<div class="container-fluid">
			<div v-if="data!=null" class="row justify-content-center">
				<div class="header mt-md-5">
					<div class="header-body">
						<div class="row align-items-center">
							<div class="col">
									<h6 class="header-pretitle">Рабочий стол / настройки</h6>
								<h1 class="header-title">Пользователь № {{data.id}}</h1>
							</div>
							<div class="col">
								<div class="position-relative" style="height:44px;">
									<div v-if="Object.keys(save_list).length>0||data_saving" class="alert alert-light" role="alert">Данные сохраняются, не закрывайте вкладку!</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="row gx-3">
						<div class="col-12 col-md-2">
							<div class="form-group">
								<label class="form-label">Активность</label>
								<input @change="active_change" :checked="data.active==1" type="checkbox"/>
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="form-group">
								<label class="form-label">Полное имя</label>
								<lg-input v-model="data.title"/>
							</div>
						</div>
						<div class="col-12 col-md-6 text-md-end d-flex justify-content-md-end">
							<div class="form-group float-end">
								<label class="form-label invisible d-none d-md-block">К списку</label>
								<button @click="save_manual(false)" type="button" class="btn btn-success" :disabled="data.client==0"> Сохранить</button>
							</div>
							<div class="form-group float-end">
								<label class="form-label invisible d-none d-md-block">К списку</label>
								<button @click="save_manual(true)" type="button" class="btn btn-secondary ms-2 mb-2">К списку</button>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-2">
						<div class="form-group">
							<label class="form-label">Телефон</label>
							<lg-input type="tel" v-model="data.login" class="form-control" placeholder="+7 (999) 888-77-66" :mask="'+ 7 (999) 999-99-99'"/>
						</div>
					</div>
					<div class="col-12 col-md-2">
						<div class="form-group">
							<label class="form-label">Пароль</label>
							<input v-model="password1" class="form-control" type="password" placeholder="Введите новый пароль">
						</div>
						<div class="form-group">
							<input v-model="password2" class="form-control" type="password" placeholder="Введите новый пароль еще раз">
							<div v-if="error_1" class="badge bg-danger-soft">{{error_text_1}}</div>
						</div>
					</div>
					<div class="row gx-3">
						<div class="col-12 col-md-2">
							<div class="form-group">
								<label class="form-label">ID в telegram</label>
								<lg-input v-model="data.tm_id"/>
							</div>
						</div>
					</div>
					<div class="row gx-3">
						<div class="col-12 col-md-2">
							<div class="form-group">
								<label class="form-label">Группа</label>
									<p v-for="(row,k) in group_select">{{row.title}} <input @change="group_change" :param_id="row.id" :checked="row.hasOwnProperty('check')&&row.check" type="checkbox"/></p>
							</div>
						</div>
					</div>
					<div class="row gx-3">
						<div class="col-12 col-md-2">
							<div class="form-group">
								<label class="form-label">Компания</label>
								<p v-for="(row,k) in unit_select">{{row.title}} <input @change="unit_change" :param_id="row.id" :checked="row.hasOwnProperty('check')&&row.check" type="checkbox"/></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
	import {  updatedDiff } from 'deep-object-diff';
	import objectAssignDeep from 'object-assign-deep'
	import config from '@/config.js'
	import router from "../../../router/router";

	export default {
		data() {
			return {
				url: 'settings/users',

				data: null,
				data_old: null,
				setting: null,

				save_list: {},
				data_saving: false,
				current: false,
				back_list: [],

				password1 : '',
				password2 : '',
				error_1 : false,
				error_text_1 : '',

				group_select: {},
				unit_select: {},
			}
		},
		mounted ()
		{
			this.load()
		},
		methods: {
			async load()
			{
				axios({url: config.API_LOCATION + this.url+'/edit/' + this.$route.params.id + '/'})
					.then(resp => {
						this.data = resp.data.data[Object.keys(resp.data.data)[0]]
						this.data_old = structuredClone(this.data)
						this.setting = resp.data.setting
						this.group_select=this.setting.field.group.setting.field.group.data;
						for(let k in this.data.group)
							this.group_select['id_'+this.data.group[k].group]['check']=true;
						this.unit_select=this.setting.field.unit.setting.field.unit.data;
						for(let k in this.data.unit)
							this.unit_select['id_'+this.data.unit[k].unit]['check']=true;
					})
					.catch(err => {
						console.log(err)
					})

				setTimeout(this.save, 10000)
			},
			async save()
			{
				if (Object.keys(this.save_list).length == 0||this.data_saving) {
					setTimeout(this.save, 10000)
					return
				}
				this.data_saving = true
				let save_data = {row: {}}
				save_data.row[this.data.id] = structuredClone(this.save_list)
				this.save_list = {}
				save_data.row[this.data.id].__save=1

				axios({url: config.API_LOCATION + this.url+ '/save_list/', data: save_data, method: 'POST'})
					.then(resp => {
						if (resp.data.status == 'ok') {
							this.data_saving = false
							this.current = true
							setTimeout(this.save, 10000)
						}
					})
					.catch(err => {

					})
			},
			async save_manual(close)
			{
				this.data_saving=true
				let save_data={row:{}}
				save_data.row[this.data.id]=structuredClone(this.save_list)
				this.save_list={}

				axios({url: config.API_LOCATION+this.url+'/save_list/', data: save_data, method: 'POST' })
					.then(resp => {
						if(resp.data.status=='ok') {
							this.data_saving=false
							this.current=true
							if(close)
								router.push('/'+this.url)
						}
					})
					.catch(err => {

					})
			},
			backspace()
			{
				if (this.back_list.length == 0)
					return
//			let t=this.data_old
				eval("this.data_old" + this.back_list[this.back_list.length - 1].name + "='" + this.back_list[this.back_list.length - 1].value + "'")
//			console.log("this.data_old"+this.back_list[this.back_list.length-1].name+"='"+this.back_list[this.back_list.length-1].value+"'")
//			console.log(t)
//			console.log(this.data_old)
				let update = updatedDiff(this.data, this.data_old)
				this.data = structuredClone(this.data_old)
				objectAssignDeep(this.save_list, update)
				this.back_list.pop()
//			console.log(this.back_list.slice(1,1))
			},
			save_empty()
			{
				if (this.current)
					return
				let save_data = {row: {}}
				save_data.row[this.data.__id] = {}
				axios({url: config.API_LOCATION + 'settings/users/save_list/', data: save_data, method: 'POST'})
					.then(resp => {
						if (resp.data.status == 'ok') {
							this.current = true
						}
					})
					.catch(err => {

					})
			},
			active_change(e)
			{
				let res=e.target.checked;
				this.data.active=(res?1:0)
			},
			check_password()
			{
				if(this.password1.length<7){
					this.error_1=true
					this.error_text_1='Минимальная длина пароля 7 символов'
					return
				}
				if(this.password1!=this.password2){
					this.error_1=true
					this.error_text_1='Пароли не совпадают'
					return
				}
				this.error_1=false;
				objectAssignDeep(this.save_list,{password:{value:this.password1}})
			},
			group_change(e)
			{
				let res=e.target.checked;
				let id=e.target.getAttribute('param_id');
				if(res)
					axios({url: config.API_LOCATION+'settings/users/child/'+this.data.id+'/group/add/'+id+'/' }).then(resp => {}).catch(err => {})
				else{
					let g_id='';
					for(let k in this.data.group)
						if(this.data.group[k].group==id)
							g_id=this.data.group[k].id;
					axios({url: config.API_LOCATION+'settings/users/child/'+this.data.id+'/group/delete/'+g_id+'/' }).then(resp => {}).catch(err => {})
				}
			},
			unit_change(e)
			{
				let res=e.target.checked;
				let id=e.target.getAttribute('param_id');
				if(res)
					axios({url: config.API_LOCATION+'settings/users/child/'+this.data.id+'/unit/add/'+id+'/' }).then(resp => {}).catch(err => {})
				else{
					let u_id='';
					for(let k in this.data.unit)
						if(this.data.unit[k].unit==id)
							u_id=this.data.unit[k].id;
					axios({url: config.API_LOCATION+'settings/users/child/'+this.data.id+'/unit/delete/'+u_id+'/' }).then(resp => {}).catch(err => {})
				}
			},
		},
		watch: {
			data: {
				handler()
				{
//				console.log('up')
//				console.log(this.data_old)
//				console.log(updatedDiff(this.data_old, this.data));
					let update=updatedDiff(this.data_old, this.data)
					if(Object.keys(update).length === 0)
						return
					let get_name= function(o)
					{
						return "['"+Object.keys(o)[0]+"']"+(typeof o[Object.keys(o)[0]] === 'object' && !Array.isArray(o[Object.keys(o)[0]]) && o[Object.keys(o)[0]] !== null?get_name(o[Object.keys(o)[0]]):'')
					}
					let name=get_name(update)
//				console.log(name)
					let old_value=eval('this.data_old'+name)
//				console.log(old_value)
					this.back_list.push({name:name,value:old_value})
					objectAssignDeep(this.save_list,update)
					this.data_old=structuredClone(this.data)
				},
				deep: true
			},
			password1()
			{
				this.check_password();
			},
			password2()
			{
				this.check_password();
			}
		},
	}
</script>